import { RouteRecordRaw } from "vue-router";

export const managerAppRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/manager",
		component: () => import("@/manager/layout/Layout.vue"),
		meta: {
			type: "manager-app",
		},
		children: [
			{
				path: "",
				redirect(to) {
					return `${to.params.language}/manager/dashboard`;
				},
			},
			{
				path: "dashboard",
				children: [
					{
						path: "",
						name: "manager-dashboard",
						component: () => import("@/manager/views/Dashboard.vue"),
					},
					{
						path: "download-accounts-backups",
						name: "manager-dashboard-download-accounts-backups",
						component: () => import("@/manager/views/dashboard/DownloadAccountsBackups.vue"),
					},
					{
						path: "zatca-failed-transactions",
						name: "manager-dashboard-zatca-failed-transactions",
						component: () => import("@/manager/views/dashboard/ZatcaFailedTransactions.vue"),
					},
				],
			},
			{
				path: "merchants",
				children: [
					{
						path: "active-merchants",
						name: "manager-merchants-active-merchants",
						component: () => import("@/manager/views/merchants/ActiveMerchants.vue"),
					},
					{
						path: "trial-merchants",
						name: "manager-merchants-trial-merchants",
						component: () => import("@/manager/views/merchants/TrialMerchant.vue"),
					},
					{
						path: "trial-requests",
						name: "manager-merchants-trial-requests",
						component: () => import("@/manager/views/merchants/TrialRequests.vue"),
					},
					{
						path: "expired-merchants",
						name: "manager-merchants-expired-merchants",
						component: () => import("@/manager/views/merchants/ExpiredMerchants.vue"),
					},
					{
						path: "disabled-merchants",
						name: "manager-merchants-disabled-merchants",
						component: () => import("@/manager/views/merchants/DisabledMerchants.vue"),
					},
					{
						path: "rejected-merchants",
						name: "manager-merchants-rejected-merchants",
						component: () => import("@/manager/views/merchants/RejectedMerchants.vue"),
					},
					{
						path: "all-merchants-requests",
						name: "manager-merchants-all-merchants-requests",
						component: () => import("@/manager/views/merchants/AllMerchantsRequests.vue"),
					},
				],
			},
			{
				path: "employees-merchants",
				children: [
					{
						path: "added-employees",
						name: "manager-employees-merchants-added-employees",
						component: () => import("@/manager/views/employees-merchants/AddedEmployees.vue"),
					},
					{
						path: "active-global-employees",
						name: "manager-employees-merchants-active-global-employees",
						component: () => import("@/manager/views/employees-merchants/ActiveGlobalEmployees.vue"),
					},
					{
						path: "inactive-global-employees",
						name: "manager-employees-merchants-inactive-global-employees",
						component: () => import("@/manager/views/employees-merchants/InactiveGlobalEmployees.vue"),
					},
					{
						path: "current-connections",
						name: "manager-employees-merchants-current-connections",
						component: () => import("@/manager/views/employees-merchants/CurrentConnections.vue"),
					},
				],
			},
			{
				path: "customers-merchants",
				children: [
					{
						path: "added-customers",
						name: "manager-customers-merchants-added-customers",
						component: () => import("@/manager/views/customers-merchants/AddedCustomers.vue"),
					},
					{
						path: "customers-inactive-wa",
						name: "manager-customers-merchants-customers-inactive-wa",
						component: () => import("@/manager/views/customers-merchants/CustomersInactiveWhatsapp.vue"),
					},
				],
			},
			{
				path: "zatca-setting",
				children: [
					{
						path: "",
						name: "manager-zatca-setting",
						component: () => import("@/manager/views/zatca-setting/ZatcaSetting.vue"),
					},
					{
						path: "form",
						name: "manager-zatca-setting-form",
						component: () => import("@/manager/views/zatca-setting/Form.vue"),
					},
					{
						path: "form-edit/:id",
						name: "manager-zatca-setting-form-edit",
						component: () => import("@/manager/views/zatca-setting/Form.vue"),
					},
				],
			},
			{
				path: "addon-services",
				children: [
					{
						path: "merchant-with-wa-package",
						name: "manager-addon-services-merchant-with-wa-package",
						component: () => import("@/manager/views/addon-services/MerchantWithWhatsappPackage.vue"),
					},
					{
						path: "merchant-with-wa-disconnected-package",
						name: "manager-addon-services-merchant-with-wa-disconnected-package",
						component: () => import("@/manager/views/addon-services/MerchantWithWhatsappDisconnectedPackage.vue"),
					},
					{
						path: "ads-campaigns",
						name: "manager-addon-services-ads-campaigns",
						component: () => import("@/manager/views/addon-services/ads-campaign/AdsCampaigns.vue"),
					},
					{
						path: "ads-campaign/:id/customers-report",
						name: "manager-addon-services-ads-campaign-customers-report",
						component: () => import("@/manager/views/addon-services/ads-campaign/AdsCampaignCustomersReport.vue"),
					},
					{
						path: "global-aliases",
						name: "manager-addon-services-global-aliases",
						component: () => import("@/manager/views/addon-services/GlobalAliases.vue"),
					},
				],
			},
			{
				path: "check-entries",
				children: [
					{
						path: "added-products",
						name: "manager-check-entries-added-products",
						component: () => import("@/manager/views/check-entries/AddedProducts.vue"),
					},
					{
						path: "edited-products",
						name: "manager-check-entries-edited-products",
						component: () => import("@/manager/views/check-entries/EditedProducts.vue"),
					},
					{
						path: "added-images",
						name: "manager-check-entries-added-images",
						component: () => import("@/manager/views/check-entries/AddedImages.vue"),
					},
					{
						path: "added-groups",
						name: "manager-check-entries-added-groups",
						component: () => import("@/manager/views/check-entries/AddedGroups.vue"),
					},
					{
						path: "added-services",
						name: "manager-check-entries-added-services",
						component: () => import("@/manager/views/check-entries/AddedServices.vue"),
					},
					{
						path: "added-descriptions",
						name: "manager-check-entries-added-descriptions",
						component: () => import("@/manager/views/check-entries/AddedDescriptions.vue"),
					},
				],
			},
			{
				path: "trackers",
				children: [
					{
						path: "mail-tracker",
						name: "manager-trackers-mail-tracker",
						component: () => import("@/manager/views/trackers/MailTracker.vue"),
					},
					{
						path: "qbot-tracker",
						name: "manager-trackers-qbot-tracker",
						component: () => import("@/manager/views/trackers/QbotTracker.vue"),
					},
					{
						path: "failed-login-attempts",
						name: "manager-trackers-failed-login-attempts",
						component: () => import("@/manager/views/trackers/FailedLoginAttempts.vue"),
					},
					{
						path: "blocked-ips",
						name: "manager-trackers-blocked-ips",
						component: () => import("@/manager/views/trackers/BlockedIps.vue"),
					},
					{
						path: "system-connections",
						name: "manager-trackers-system-connections",
						component: () => import("@/manager/views/trackers/SystemConnections.vue"),
					},
				],
			},
			{
				path: "technical",
				children: [
					{
						path: "js-errors",
						name: "manager-technical-js-errors",
						component: () => import("@/manager/views/technical/JSErrors.vue"),
					},
					{
						path: "development-suggestions",
						name: "manager-technical-development-suggestions",
						component: () => import("@/manager/views/technical/DevelopmentSuggestions.vue"),
					},
				],
			},
			{
				path: "marketing",
				children: [
					{
						path: "marketing-merchants-requests",
						name: "manager-marketing-marketing-merchants-requests",
						component: () => import("@/manager/views/marketing/MarketingMerchantsRequests.vue"),
					},
					{
						path: "marketing-active-merchants",
						name: "manager-marketing-marketing-active-merchants",
						component: () => import("@/manager/views/marketing/MarketingActiveMerchants.vue"),
					},
					{
						path: "marketing-setting",
						name: "manager-marketing-marketing-setting",
						component: () => import("@/manager/views/marketing/MarketingSetting.vue"),
					},
				],
			},
			{
				path: "accounting",
				children: [
					{
						path: "contracts",
						children: [
							{
								path: "",
								name: "manager-accounting-contracts",
								component: () => import("@/manager/views/accounting/contracts/Contracts.vue"),
							},
							{
								path: "contract",
								name: "manager-accounting-contracts-contract",
								component: () => import("@/manager/views/accounting/contracts/ContractForm.vue"),
							},
							{
								path: "contracts/:id",
								name: "manager-accounting-contracts-contract-edit",
								component: () => import("@/manager/views/accounting/contracts/ContractForm.vue"),
							},
						],
					},
					{
						path: "total-sales",
						children: [
							{
								path: "",
								name: "manager-accounting-total-sales",
								component: () => import("@/manager/views/accounting/total-sales/TotalSales.vue"),
							},
							{
								path: "invoice",
								name: "manager-accounting-total-sales-invoice",
								component: () => import("@/manager/views/accounting/total-sales/InvoiceForm.vue"),
							},
							{
								path: "invoice/:id",
								name: "manager-accounting-total-sales-invoice-edit",
								component: () => import("@/manager/views/accounting/total-sales/InvoiceForm.vue"),
							},
						],
					},
					{
						path: "accounting/payments",
						name: "manager-accounting-payments",
						component: () => import("@/manager/views/accounting/Payments.vue"),
					},
					{
						path: "payments-requests",
						children: [
							{
								path: "",
								name: "manager-accounting-payments-requests",
								component: () => import("@/manager/views/accounting/payments-requests/PaymentsRequests.vue"),
							},
							{
								path: "payment-request",
								name: "manager-accounting-payments-requests-payment-request",
								component: () => import("@/manager/views/accounting/payments-requests/PaymentRequestForm.vue"),
							},
							{
								path: "payment-request/:id",
								name: "manager-accounting-payments-requests-payment-request-edit",
								component: () => import("@/manager/views/accounting/payments-requests/PaymentRequestForm.vue"),
							},
						],
					},
				],
			},
			{
				path: "platform-setting",
				children: [
					{
						path: "platform-setting",
						name: "manager-platform-setting-platform-setting",
						component: () => import("@/manager/views/platform-setting/PlatformSetting.vue"),
					},
					{
						path: "employees",
						name: "manager-platform-setting-employees",
						component: () => import("@/manager/views/platform-setting/Employees.vue"),
					},
					{
						path: "suggested-groups",
						name: "manager-platform-setting-suggested-groups",
						component: () => import("@/manager/views/platform-setting/SuggestedGroups.vue"),
					},
					{
						path: "suggested-services",
						name: "manager-platform-setting-suggested-services",
						component: () => import("@/manager/views/platform-setting/SuggestedServices.vue"),
					},
					{
						path: "suggested-products",
						name: "manager-platform-setting-suggested-products",
						component: () => import("@/manager/views/platform-setting/SuggestedProducts.vue"),
					},
					{
						path: "countries-list",
						name: "manager-platform-setting-countries-list",
						component: () => import("@/manager/views/platform-setting/CountriesList.vue"),
					},
					{
						path: "cities-list",
						name: "manager-platform-setting-cities-list",
						component: () => import("@/manager/views/platform-setting/CitiesList.vue"),
					},
					{
						path: "email-setting",
						name: "manager-platform-setting-email-setting",
						component: () => import("@/manager/views/platform-setting/EmailSetting.vue"),
					},
					{
						path: "whatsapp-setting",
						name: "manager-platform-setting-whatsapp-setting",
						component: () => import("@/manager/views/platform-setting/WhatsappSetting.vue"),
					},
					{
						path: "payment-gateway-setting",
						name: "manager-platform-setting-payment-gateway-setting",
						component: () => import("@/manager/views/platform-setting/PaymentGatewaySetting.vue"),
					},
					{
						path: "payments-methods",
						name: "manager-platform-setting-payments-methods",
						component: () => import("@/manager/views/platform-setting/PaymentsMethods.vue"),
					},
					{
						path: "platform-updates",
						name: "manager-platform-setting-platform-updates",
						component: () => import("@/manager/views/platform-setting/PlatformUpdates.vue"),
					},
					{
						path: "whatsapp-packages",
						name: "manager-platform-setting-whatsapp-packages",
						component: () => import("@/manager/views/platform-setting/WhatsappPackages.vue"),
					},
					{
						path: "allowed-countries",
						name: "manager-platform-setting-allowed-countries",
						component: () => import("@/manager/views/platform-setting/AllowedCountries.vue"),
					},
				],
			},
			{
				path: "website-setting",
				children: [
					{
						path: "website-setting",
						name: "manager-website-setting-website-setting",
						component: () => import("@/manager/views/website-setting/WebsiteSetting.vue"),
					},
					{
						path: "terms-and-conditions",
						name: "manager-website-setting-terms-and-conditions",
						component: () => import("@/manager/views/website-setting/TermsAndConditions.vue"),
					},
					{
						path: "privacy-policy",
						name: "manager-website-setting-privacy-policy",
						component: () => import("@/manager/views/website-setting/PrivacyPolicy.vue"),
					},
					{
						path: "frequent-asked-questions",
						name: "manager-website-setting-frequent-asked-questions",
						component: () => import("@/manager/views/website-setting/FAQ.vue"),
					},

					{
						path: "setting/social-media-setting",
						name: "manager-website-setting-social-media-setting",
						component: () => import("@/manager/views/website-setting/SocialMediaSetting.vue"),
					},
				],
			},
			{
				path: "seo-setting",
				children: [
					{
						path: "seo-report",
						name: "manager-seo-setting-seo-report",
						component: () => import("@/manager/views/seo_setting/SeoReport.vue"),
					},
					{
						path: "seo-page-details",
						name: "manager-seo-setting-seo-add-page-details",
						component: () => import("@/manager/views/seo_setting/SeoPageDetails.vue"),
					},
					{
						path: "seo-page-details/:id",
						name: "manager-seo-setting-seo-edit-page-details",
						component: () => import("@/manager/views/seo_setting/SeoPageDetails.vue"),
					},
					{
						path: "google-analytics",
						name: "manager-seo-setting-google-analytics",
						component: () => import("@/manager/views/seo_setting/GoogleAnalytics.vue"),
					},
					{
						path: "redirects",
						name: "manager-seo-setting-redirects",
						component: () => import("@/manager/views/seo_setting/Redirects.vue"),
					},
					{
						path: "dummy_links",
						name: "manager-seo-setting-dummy-links",
						component: () => import("@/manager/views/seo_setting/DummyLinks.vue"),
					},
					{
						path: "generate-server-js",
						name: "manager-seo-setting-generate-server-js",
						component: () => import("@/manager/views/seo_setting/GenerateServerJs.vue"),
					},
					{
						path: "default-seo-records",
						name: "manager-seo-setting-default-seo-records",
						component: () => import("@/manager/views/seo_setting/DefaultSeoRecords.vue"),
					},
				],
			},
			{
				path: "logout",
				name: "manager-logout",
				component: () => import("@/manager/views/SignIn.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/manager",
		component: () => import("@/manager/layout/AuthLayout.vue"),
		meta: {
			type: "manager-auth",
		},
		children: [
			{
				path: "sign-in",
				name: "manager-sign-in",
				component: () => import("@/manager/views/SignIn.vue"),
			},
		],
	},
];

export const managerGeneralRoutes: Array<RouteRecordRaw> = [
	// Account Invoice
	{
		path: "/:language(en|ar|)?/account-invoice/:uniqueId",
		name: "account-invoice",
		component: () => import("@/manager/views/accounting/total-sales/WebInvoice.vue"),
	},

	// Contract Invoice
	{
		path: "/:language(en|ar|)?/account-contract/:uniqueId",
		name: "account-contract",
		component: () => import("@/manager/views/accounting/contracts/WebContract.vue"),
	},
];
